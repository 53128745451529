<template>
  <div class="sm:px-5 md:px-5 lg:mx-20% py-5">
    <da-datatable :headers="headers" :values="patients" :elevation="3" :hidesearch="false" :hidepagination="false">

            <template #action="{ data }">
                <!-- <div class="flex sm:px md:px-10 lg:px-10"> -->

<da-button-900 @click.native="goTo(data.id_person)" :label="'HISTORIAL'" r2xl swpr class="w-full px-2  tracking-wider rounded-md"><v-icon small color="white">mdi mdi-open-in-new</v-icon></da-button-900>


                <!-- </div> -->
            </template>

    </da-datatable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VuexMixin from '../../mixins/VuexMixin';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
export default {
    mixins: [VuexMixin],
    data: function() {
        return {
            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Apellido',  value: 'surname' },
                { text: 'Ocupación',  value: 'ocupation' },
                { text: 'Edad',   value: 'age' },
                { text: 'Acción',   value: 'action' }
            ],
            listPatient:  []
        }
    },
    created() {
      this.isLoading = true
      this.onSearch()
      this.isLoading = false
    },
    computed: {
     ...mapGetters(moduleTypes.DASHBOARD, {
          patients: fnTypes.getPatients,
          hasErrorInService: fnTypes.hasErrorInService
       }),
    },
    methods: {
      ...mapActions(moduleTypes.DASHBOARD, [
          fnTypes.getPatients
      ]),

    onSearch: async function() {
        var data = {
          'param':''
        }
        await this.ActionWrapp( moduleTypes.DASHBOARD, fnTypes.getPatients,  data);
        this.listPatient = this.patients
      },
      goTo(id){
        this.$router.push('/px/history/'+id)
      }
    }

}
</script>

<style>

</style>